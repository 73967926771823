var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      show: _vm.showSidebar,
      fetching: _vm.loading,
      title: _vm.$t("Detalhes do extrato"),
      width: "850px",
      "no-close-on-backdrop": false,
    },
    on: { hidden: _vm.hide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _vm.isTransfer
              ? [
                  _c(
                    "b-card-actions",
                    { attrs: { title: _vm.$t("Dados"), "no-actions": "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-store_origin",
                                  type: "label",
                                  filter: "storeName",
                                  label: _vm.$t("Loja Origem"),
                                },
                                model: {
                                  value: _vm.data.storeOrigin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "storeOrigin", $$v)
                                  },
                                  expression: "data.storeOrigin",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-checking_account_origin",
                                  value: _vm.data.checkingAccountOrigin.name,
                                  type: "label",
                                  label: _vm.$t("Conta corrente Origem"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-store_destination",
                                  type: "label",
                                  filter: "storeName",
                                  label: _vm.$t("Loja Destino"),
                                },
                                model: {
                                  value: _vm.data.storeDestination,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "storeDestination", $$v)
                                  },
                                  expression: "data.storeDestination",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-checking_account_destination",
                                  value:
                                    _vm.data.checkingAccountDestination.name,
                                  type: "label",
                                  label: _vm.$t("Conta corrente Destino"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "7" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-description",
                                  type: "label",
                                  label: _vm.$t("Descrição"),
                                },
                                model: {
                                  value: _vm.data.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "description", $$v)
                                  },
                                  expression: "data.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-type",
                                  type: "label",
                                  options: _vm.checkingAccountTransferOptions(),
                                  label: _vm.$t("Tipo"),
                                },
                                model: {
                                  value: _vm.data.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "type", $$v)
                                  },
                                  expression: "data.type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-statement_date",
                                  type: "label",
                                  filter: "date",
                                  label: _vm.$t("Data da emissão"),
                                },
                                model: {
                                  value: _vm.data.statementDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "statementDate", $$v)
                                  },
                                  expression: "data.statementDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-value",
                                  value: _vm.data.value,
                                  type: "label",
                                  filter: "currency",
                                  label: _vm.$t("Valor"),
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.data.user
                            ? _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "cc_sidebar-user",
                                      value: _vm.data.user.name,
                                      type: "label",
                                      label: _vm.$t("Criado por"),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : [
                  _c(
                    "b-card-actions",
                    { attrs: { title: _vm.$t("Dados"), "no-actions": "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-store",
                                  type: "label",
                                  filter: "storeName",
                                  label: _vm.$t("Loja"),
                                },
                                model: {
                                  value: _vm.data.store,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "store", $$v)
                                  },
                                  expression: "data.store",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "5" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-description",
                                  type: "label",
                                  label: _vm.$t("Descrição"),
                                },
                                model: {
                                  value: _vm.data.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "description", $$v)
                                  },
                                  expression: "data.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-type",
                                  type: "label",
                                  options: _vm.writeOffTypeOptions(),
                                  label: _vm.$t("Tipo"),
                                },
                                model: {
                                  value: _vm.data.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "type", $$v)
                                  },
                                  expression: "data.type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-payment_date",
                                  type: "label",
                                  filter: "date",
                                  label: _vm.$t("Data do pagamento"),
                                },
                                model: {
                                  value: _vm.data.paymentDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "paymentDate", $$v)
                                  },
                                  expression: "data.paymentDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-checking_account",
                                  value: _vm.data.checkingAccount.name,
                                  type: "label",
                                  label: _vm.$t("Conta corrente"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "cc_sidebar-value",
                                  value: _vm.data.value,
                                  type: "label",
                                  filter: "currency",
                                  label: _vm.$t("Valor"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.getInstallments.length > 0
                    ? _c(
                        "b-card-actions",
                        {
                          attrs: {
                            title: _vm.$t("Parcelas"),
                            "no-actions": "",
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-table", {
                                    staticClass: "bordered",
                                    attrs: {
                                      "show-empty": "",
                                      responsive: "",
                                      fields: _vm.getFields,
                                      items: _vm.getInstallments,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(accPayInfo)",
                                          fn: function (row) {
                                            return [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Id da Parcela")
                                                  ) + ":"
                                                ),
                                              ]),
                                              _vm._v(" " + _vm._s(row.item.id)),
                                              _c("br"),
                                              row.item.accountsPayable.document
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Nº doc.")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          "" +
                                                            row.item
                                                              .accountsPayable
                                                              .document +
                                                            (row.item
                                                              .accountsPayable
                                                              .documentType
                                                              ? " (" +
                                                                row.item
                                                                  .accountsPayable
                                                                  .documentType +
                                                                ")"
                                                              : "")
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Parc.")) + ":"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.item.installment
                                                      ? [
                                                          row.item.installment,
                                                          row.item
                                                            .accountsPayable
                                                            .installmentCount,
                                                        ]
                                                          .filter(Boolean)
                                                          .join("/")
                                                      : "-"
                                                  )
                                              ),
                                              _c("br"),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Forma Pagto.")
                                                  ) + ":"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.item.paymentMethod.name
                                                  )
                                              ),
                                              _c("br"),
                                              row.item.accountsPayable
                                                .purchaseOrderId
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Pedido")
                                                          ) + ":"
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "hyperlink",
                                                          attrs: {
                                                            to: {
                                                              name: "purchase-order-details",
                                                              params: {
                                                                id: row.item
                                                                  .accountsPayable
                                                                  .purchaseOrderId,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon: "ExternalLinkIcon",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row.item
                                                                  .accountsPayable
                                                                  .purchaseOrderId
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              row.item.accountsPayable.saleId
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Venda")
                                                          ) + ":"
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "hyperlink",
                                                          attrs: {
                                                            to: {
                                                              name: "order-read-only",
                                                              params: {
                                                                id: row.item
                                                                  .accountsPayable
                                                                  .saleId,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon: "ExternalLinkIcon",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row.item
                                                                  .accountsPayable
                                                                  .saleId
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              row.item.accountsPayable
                                                .statementDate &&
                                              row.item.accountsPayable
                                                .statementDate !==
                                                row.item.accountsPayable
                                                  .createdDate
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Emis.")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("date")(
                                                            row.item
                                                              .accountsPayable
                                                              .statementDate
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Lanç.")) + ":"
                                                ),
                                              ]),
                                              row.item.accountsPayable
                                                .createdDate
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("date")(
                                                            row.item
                                                              .accountsPayable
                                                              .createdDate
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("span", [_vm._v(" - ")]),
                                              _c("br"),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Venc.")) + ":"
                                                ),
                                              ]),
                                              row.item.dueDate
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("date")(
                                                            row.item.dueDate
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("span", [_vm._v(" - ")]),
                                              _c("br"),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Pago")) + ":"
                                                ),
                                              ]),
                                              row.item.paymentDate
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("date")(
                                                            row.item.paymentDate
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("span", [_vm._v(" - ")]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(accPayAditionalInfo)",
                                          fn: function (row) {
                                            return [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Conta Gerencial")
                                                  ) + ":"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.item.accountsPayable
                                                      .managementAccount.name
                                                  )
                                              ),
                                              _c("br"),
                                              row.item.accountsPayable.supplier
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Fornecedor")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("cpfCnpj")(
                                                            row.item
                                                              .accountsPayable
                                                              .supplier.document
                                                          )
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          row.item
                                                            .accountsPayable
                                                            .supplier.name
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              row.item.accountsPayable.customer
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Cliente")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          row.item
                                                            .accountsPayable
                                                            .customer.name
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Desc.")) + ":"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.item.accountsPayable
                                                      .description || "-"
                                                  )
                                              ),
                                              _c("br"),
                                              row.item.accountsPayable.payBox
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Caixa Nº")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          row.item
                                                            .accountsPayable
                                                            .payBox.number ||
                                                            "-"
                                                        )
                                                    ),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Usuário")) +
                                                    ":"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.item.createdBy || "-"
                                                  )
                                              ),
                                              _c("br"),
                                              row.item.checkingAccount
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Conta corrente"
                                                          )
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.item
                                                              .checkingAccount
                                                              ? row.item
                                                                  .checkingAccount
                                                                  .name
                                                              : ""
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ])
                                                : _vm._e(),
                                              row.item.accountsPayable
                                                .comments || row.item.comments
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Observação")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            [
                                                              row.item
                                                                .accountsPayable
                                                                .comments,
                                                              row.item.comments,
                                                            ]
                                                              .filter(Boolean)
                                                              .join(" - ")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(receivInfo)",
                                          fn: function (row) {
                                            return [
                                              row.item.receivable.sale &&
                                              row.item.receivable.sale.id
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Venda")
                                                          ) + ":"
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "hyperlink",
                                                          attrs: {
                                                            to: {
                                                              name: "order-read-only",
                                                              params: {
                                                                id: row.item
                                                                  .receivable
                                                                  .sale.id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon: "ExternalLinkIcon",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row.item
                                                                  .receivable
                                                                  .sale.id
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              row.item.createdBy
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Operador")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          row.item.createdBy
                                                        )
                                                    ),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              row.item.receivable.issueDate &&
                                              _vm.date(
                                                row.item.receivable.issueDate
                                              ) !==
                                                _vm.date(
                                                  row.item.receivable
                                                    .createdDate
                                                )
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Emis.")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("date")(
                                                            row.item.receivable
                                                              .issueDate,
                                                            "-"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Lanç.")) + ":"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      row.item.receivable
                                                        .createdDate
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Venc.")) + ":"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      row.item.dueDate,
                                                      "-"
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Pago")) + ":"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      row.item.paymentDate,
                                                      "-"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(receivAditionalInfo)",
                                          fn: function (row) {
                                            return [
                                              row.item.receivable
                                                .managementAccount
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Conta Gerencial"
                                                          )
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          row.item.receivable
                                                            .managementAccount
                                                            .name
                                                        )
                                                    ),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              row.item.receivable.customer
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Cliente")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          row.item.receivable
                                                            .customer.name
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Desc.")) + ":"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.item.receivable
                                                      .description || "-"
                                                  )
                                              ),
                                              _c("br"),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(action)",
                                          fn: function () {
                                            return [
                                              _c("e-grid-actions", {
                                                attrs: {
                                                  "show-update": false,
                                                  "show-delete": true,
                                                },
                                                on: {
                                                  update: function () {},
                                                  delete: function () {},
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "custom-foot",
                                          fn: function () {
                                            return [
                                              _vm.isFromAccountPayable
                                                ? [
                                                    _c("tr", [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [_vm._v(" Total ")]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  _vm.getTotals
                                                                    .valueTotal
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("th"),
                                                    ]),
                                                  ]
                                                : _vm._e(),
                                              _vm.isFromReceivable
                                                ? [
                                                    _c("tr", [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [_vm._v(" Total ")]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  _vm.getTotals
                                                                    .valueTotal
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  _vm.getTotals
                                                                    .commissionTotal
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  _vm.getTotals
                                                                    .paidTotal
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("th"),
                                                    ]),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2142228667
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
      {
        key: "sidebar-footer",
        fn: function () {
          return [
            _c("e-button", {
              attrs: {
                variant: "primary",
                icon: "x",
                text: _vm.$t("Fechar"),
                busy: _vm.saving,
              },
              on: { click: _vm.hide },
            }),
            _vm.showReverse
              ? _c("e-button", {
                  staticClass: "ml-1",
                  attrs: {
                    busy: _vm.saving,
                    variant: "danger",
                    icon: "x-circle",
                    text: _vm.$t("Estornar"),
                  },
                  on: { click: _vm.localReverse },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }