<template>
  <e-sidebar
    :show="showSidebar"
    :fetching="loading"
    :title="$t('Detalhes do extrato')"
    width="850px"
    :no-close-on-backdrop="false"
    @hidden="hide"
  >
    <template #content>
      <!-- TODO componentizar: transferencia e WriteOff -->
      <template v-if="isTransfer">
        <b-card-actions
          :title="$t('Dados')"
          no-actions
        >
          <b-row>
            <b-col md="3">
              <FormulateInput
                id="cc_sidebar-store_origin"
                v-model="data.storeOrigin"
                type="label"
                filter="storeName"
                :label="$t('Loja Origem')"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="cc_sidebar-checking_account_origin"
                :value="data.checkingAccountOrigin.name"
                type="label"
                :label="$t('Conta corrente Origem')"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="cc_sidebar-store_destination"
                v-model="data.storeDestination"
                type="label"
                filter="storeName"
                :label="$t('Loja Destino')"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="cc_sidebar-checking_account_destination"
                :value="data.checkingAccountDestination.name"
                type="label"
                :label="$t('Conta corrente Destino')"
              />
            </b-col>

            <b-col md="7">
              <FormulateInput
                id="cc_sidebar-description"
                v-model="data.description"
                type="label"
                :label="$t('Descrição')"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="cc_sidebar-type"
                v-model="data.type"
                type="label"
                :options="checkingAccountTransferOptions()"
                :label="$t('Tipo')"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="cc_sidebar-statement_date"
                v-model="data.statementDate"
                type="label"
                filter="date"
                :label="$t('Data da emissão')"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="cc_sidebar-value"
                :value="data.value"
                type="label"
                filter="currency"
                :label="$t('Valor')"
              />
            </b-col>
            <b-col
              v-if="data.user"
              md="3"
            >
              <FormulateInput
                id="cc_sidebar-user"
                :value="data.user.name"
                type="label"
                :label="$t('Criado por')"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </template>

      <template v-else>
        <b-card-actions
          :title="$t('Dados')"
          no-actions
        >
          <b-row>
            <b-col md="4">
              <FormulateInput
                id="cc_sidebar-store"
                v-model="data.store"
                type="label"
                filter="storeName"
                :label="$t('Loja')"
              />
            </b-col>
            <b-col md="5">
              <FormulateInput
                id="cc_sidebar-description"
                v-model="data.description"
                type="label"
                :label="$t('Descrição')"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="cc_sidebar-type"
                v-model="data.type"
                type="label"
                :options="writeOffTypeOptions()"
                :label="$t('Tipo')"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="cc_sidebar-payment_date"
                v-model="data.paymentDate"
                type="label"
                filter="date"
                :label="$t('Data do pagamento')"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="cc_sidebar-checking_account"
                :value="data.checkingAccount.name"
                type="label"
                :label="$t('Conta corrente')"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="cc_sidebar-value"
                :value="data.value"
                type="label"
                filter="currency"
                :label="$t('Valor')"
              />
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          v-if="getInstallments.length > 0"
          :title="$t('Parcelas')"
          no-actions
        >
          <b-row>
            <b-col>
              <b-table
                show-empty
                responsive
                class="bordered"
                :fields="getFields"
                :items="getInstallments"
              >
                <!-- contas a pagar -->

                <template #cell(accPayInfo)="row">
                  <b>{{ $t('Id da Parcela') }}:</b> {{ row.item.id }}<br>
                  <!-- <b>{{ $t('Loja') }}:</b> {{ row.item.accountsPayable.store | storeName }}<br> -->
                  <div v-if="row.item.accountsPayable.document">
                    <b>{{ $t('Nº doc.') }}:</b>
                    {{
                      `${row.item.accountsPayable.document}${
                        row.item.accountsPayable.documentType
                          ? ` (${row.item.accountsPayable.documentType})`
                          : ''
                      }`
                    }}
                  </div>
                  <b>{{ $t('Parc.') }}:</b>
                  {{
                    row.item.installment
                      ? [row.item.installment, row.item.accountsPayable.installmentCount]
                        .filter(Boolean)
                        .join('/')
                      : '-'
                  }}<br>
                  <b>{{ $t('Forma Pagto.') }}:</b> {{ row.item.paymentMethod.name }}<br>
                  <div v-if="row.item.accountsPayable.purchaseOrderId">
                    <b>{{ $t('Pedido') }}:</b>&nbsp;
                    <router-link
                      :to="{
                        name: 'purchase-order-details',
                        params: { id: row.item.accountsPayable.purchaseOrderId },
                      }"
                      class="hyperlink"
                    >
                      <feather-icon icon="ExternalLinkIcon" />
                      {{ row.item.accountsPayable.purchaseOrderId }}
                    </router-link>
                  </div>
                  <div v-if="row.item.accountsPayable.saleId">
                    <b>{{ $t('Venda') }}:</b>&nbsp;
                    <router-link
                      :to="{
                        name: 'order-read-only',
                        params: { id: row.item.accountsPayable.saleId },
                      }"
                      class="hyperlink"
                    >
                      <feather-icon icon="ExternalLinkIcon" />
                      {{ row.item.accountsPayable.saleId }}
                    </router-link>
                  </div>

                  <div
                    v-if="
                      row.item.accountsPayable.statementDate &&
                        row.item.accountsPayable.statementDate !==
                        row.item.accountsPayable.createdDate
                    "
                  >
                    <b>{{ $t('Emis.') }}:</b>&nbsp;
                    {{ row.item.accountsPayable.statementDate | date }}
                  </div>

                  <b>{{ $t('Lanç.') }}:</b>
                  <span v-if="row.item.accountsPayable.createdDate">
                    {{ row.item.accountsPayable.createdDate | date }}
                  </span>
                  <span v-else> - </span>
                  <br>

                  <b>{{ $t('Venc.') }}:</b>
                  <span v-if="row.item.dueDate"> {{ row.item.dueDate | date }} </span>
                  <span v-else> - </span>
                  <br>

                  <b>{{ $t('Pago') }}:</b>
                  <span v-if="row.item.paymentDate"> {{ row.item.paymentDate | date }} </span>
                  <span v-else> - </span>
                </template>

                <template #cell(accPayAditionalInfo)="row">
                  <b>{{ $t('Conta Gerencial') }}:</b>
                  {{ row.item.accountsPayable.managementAccount.name }}<br>
                  <div v-if="row.item.accountsPayable.supplier">
                    <b>{{ $t('Fornecedor') }}:</b>
                    {{ row.item.accountsPayable.supplier.document | cpfCnpj }} -
                    {{ row.item.accountsPayable.supplier.name }}
                  </div>
                  <div v-if="row.item.accountsPayable.customer">
                    <b>{{ $t('Cliente') }}:</b> {{ row.item.accountsPayable.customer.name }}
                  </div>
                  <b>{{ $t('Desc.') }}:</b> {{ row.item.accountsPayable.description || '-' }}<br>
                  <div v-if="row.item.accountsPayable.payBox">
                    <b>{{ $t('Caixa Nº') }}:</b> {{ row.item.accountsPayable.payBox.number || '-'
                    }}<br>
                  </div>
                  <b>{{ $t('Usuário') }}:</b> {{ row.item.createdBy || '-' }}<br>

                  <div v-if="row.item.checkingAccount">
                    <b>{{ $t('Conta corrente') }}:</b>
                    <span>
                      {{ row.item.checkingAccount ? row.item.checkingAccount.name : '' }}
                    </span>
                  </div>

                  <div v-if="row.item.accountsPayable.comments || row.item.comments">
                    <b>{{ $t('Observação') }}:</b>
                    <span>
                      {{
                        [row.item.accountsPayable.comments, row.item.comments]
                          .filter(Boolean)
                          .join(' - ')
                      }} </span><br>
                  </div>
                </template>

                <!-- Recebível -->

                <template #cell(receivInfo)="row">
                  <!-- <b>{{ $t('Loja') }}:</b> {{ row.item.receivable.store | storeName }}<br> -->
                  <div v-if="row.item.receivable.sale && row.item.receivable.sale.id">
                    <b>{{ $t('Venda') }}:</b>&nbsp;
                    <router-link
                      :to="{ name: 'order-read-only', params: { id: row.item.receivable.sale.id } }"
                      class="hyperlink"
                    >
                      <feather-icon icon="ExternalLinkIcon" />
                      {{ row.item.receivable.sale.id }}
                    </router-link>
                  </div>
                  <div v-if="row.item.createdBy">
                    <b>{{ $t('Operador') }}:</b> {{ row.item.createdBy }}<br>
                  </div>
                  <div
                    v-if="
                      row.item.receivable.issueDate &&
                        date(row.item.receivable.issueDate) !== date(row.item.receivable.createdDate)
                    "
                  >
                    <b>{{ $t('Emis.') }}:</b>&nbsp;
                    {{ row.item.receivable.issueDate | date('-') }}
                  </div>
                  <b>{{ $t('Lanç.') }}:</b> {{ row.item.receivable.createdDate | date }}<br>
                  <b>{{ $t('Venc.') }}:</b> {{ row.item.dueDate | date('-') }}<br>
                  <b>{{ $t('Pago') }}:</b> {{ row.item.paymentDate | date('-') }}
                </template>

                <template #cell(receivAditionalInfo)="row">
                  <div v-if="row.item.receivable.managementAccount">
                    <b>{{ $t('Conta Gerencial') }}:</b>
                    {{ row.item.receivable.managementAccount.name }}<br>
                  </div>
                  <div v-if="row.item.receivable.customer">
                    <b>{{ $t('Cliente') }}:</b> {{ row.item.receivable.customer.name }}
                  </div>
                  <b>{{ $t('Desc.') }}:</b> {{ row.item.receivable.description || '-' }}<br>
                </template>

                <template #cell(action)="">
                  <e-grid-actions
                    :show-update="false"
                    :show-delete="true"
                    @update="() => {}"
                    @delete="() => {}"
                  />
                </template>

                <template #custom-foot>
                  <template v-if="isFromAccountPayable">
                    <tr>
                      <th class="text-right">
                        Total
                      </th>
                      <th class="text-right">
                        {{ getTotals.valueTotal | currency }}
                      </th>
                      <th />
                    </tr>
                  </template>
                  <template v-if="isFromReceivable">
                    <tr>
                      <th class="text-right">
                        Total
                      </th>
                      <th class="text-right">
                        {{ getTotals.valueTotal | currency }}
                      </th>
                      <th class="text-right">
                        {{ getTotals.commissionTotal | currency }}
                      </th>
                      <th class="text-right">
                        {{ getTotals.paidTotal | currency }}
                      </th>
                      <th />
                    </tr>
                  </template>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card-actions>
      </template>
    </template>

    <template #sidebar-footer>
      <e-button
        variant="primary"
        icon="x"
        :text="$t('Fechar')"
        :busy="saving"
        @click="hide"
      />
      <e-button
        v-if="showReverse"
        class="ml-1"
        :busy="saving"
        variant="danger"
        icon="x-circle"
        :text="$t('Estornar')"
        @click="localReverse"
      />
    </template>
  </e-sidebar>
</template>

<script>
import _ from 'lodash'
import { BRow, BCol, BTable } from 'bootstrap-vue'
import { alerts, financialDomain } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import ESidebar from '@/views/components/ESidebar.vue'
import EButton from '@/views/components/EButton.vue'

const getInitialSidebarData = () => ({
  id: null,
  store: null,
  checkingAccount: {},
  user: null,
  description: null,
  value: null,
  paymentDate: null,
  type: null,
  items: [],
})

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCardActions,
    EGridActions,
    ESidebar,
    EButton,
  },

  mixins: [alerts, financialDomain],

  props: {},

  data() {
    return {
      showSidebar: false,
      loading: false,
      saving: false,
      data: getInitialSidebarData(),
    }
  },

  computed: {
    getInstallments() {
      const { receivableInstalment, accountsPayableInstalment } = this.data
      if (!(receivableInstalment || accountsPayableInstalment)) return []

      if (this.isFromAccountPayable) return accountsPayableInstalment
      if (this.isFromReceivable) return receivableInstalment

      return []
    },

    getFields() {
      if (this.isFromAccountPayable) return this.accountPayableFields
      if (this.isFromReceivable) return this.receivableFields
      return []
    },

    getTotals() {
      if (this.isFromAccountPayable)
        return this.getInstallments.reduce(
          (totals, inst) => ({
            valueTotal: _.toNumber(inst.value) + totals.valueTotal,
          }),
          { valueTotal: 0 }
        )
      if (this.isFromReceivable)
        return this.getInstallments.reduce(
          (totals, inst) => ({
            commissionTotal: _.toNumber(inst.commissionValue) + totals.commissionTotal,
            paidTotal: _.toNumber(inst.paidValue) + totals.paidTotal,
            valueTotal: _.toNumber(inst.value) + totals.valueTotal,
          }),
          {
            commissionTotal: 0,
            paidTotal: 0,
            valueTotal: 0,
          }
        )

      return {}
    },

    isFromAccountPayable() {
      return this.data.type === this.writeOffTypeEnum.ACCOUNTS_PAYABLE
    },
    isFromReceivable() {
      return this.data.type === this.writeOffTypeEnum.RECEIVABLE
    },

    isDeposit() {
      return this.data.type === this.checkingAccountTransferEnum.DEPOSIT
    },
    isTransference() {
      return this.data.type === this.checkingAccountTransferEnum.TRANSFERENCE
    },
    isTransfer() {
      return this.isDeposit || this.isTransference
    },

    showReverse() {
      if (this.isFromAccountPayable) {
        return this.$can('Reverse', 'FinancialWriteOff')
      }
      if (this.isFromReceivable) {
        const { receivableInstalment } = this.data
        const isCash =
          Array.isArray(receivableInstalment) && receivableInstalment.length > 0
            ? receivableInstalment[0].paymentMethod?.id === 1
            : false
        return this.$can('Reverse', 'FinancialWriteOff') && !isCash
      }

      if (this.isDeposit) {
        return this.$can('Reverse', 'BankDeposit')
      }
      if (this.isTransference) {
        return this.$can('Reverse', 'CheckingAccountTransfer')
      }

      return false
    },

    accountPayableFields() {
      return [
        {
          label: this.$t('Informações'),
          key: 'accPayInfo',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: value => this.$options.filters.currency(value),
          thStyle: { minWidth: '110px', width: '110px' },
          sortable: true,
        },
        {
          label: this.$t('Info. Adicionais'),
          key: 'accPayAditionalInfo',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
      ]
    },

    receivableFields() {
      return [
        {
          label: this.$t('Informações'),
          key: 'receivInfo',
          // thStyle: { 'min-width': '200px' },
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value),
        },
        {
          label: this.$t('Taxa'),
          key: 'commissionValue',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value, '-'),
        },
        {
          label: this.$t('Pago'),
          key: 'paidValue',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value, '-'),
        },
        {
          label: this.$t('Info. Adicionais'),
          key: 'receivAditionalInfo',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
      ]
    },
  },

  methods: {
    date(value) {
      return this.$options.filters.date(value)
    },

    async show(checkingAccount) {
      this.showSidebar = true
      this.loading = true
      const { financialWriteOffId, checkingAccountTransferId } = checkingAccount

      try {
        if (financialWriteOffId) {
          const { data } = await this.$http.get(
            `/api/financial/write-offs/by-id/${financialWriteOffId}`
          )

          this.data = data
        } else if (checkingAccountTransferId) {
          const { data } = await this.$http.get(
            `/api/financial/checking-account-transfers/${checkingAccountTransferId}`
          )

          this.data = data
        }
      } catch (error) {
        this.showError({ error })
        this.hide()
      } finally {
        this.loading = false
      }
    },

    async localReverse() {
      if (this.isTransfer) {
        await this.reverseTransfer()
      } else {
        await this.reverseWriteOff()
      }
    },

    async reverseTransfer() {
      const transfer = this.data
      const confirmed = await this.confirm({
        text: this.$t(
          transfer.type === 'Deposit'
            ? 'O depósito será estornado e os lançamentos nas contas correntes serão excluídos.'
            : 'A transferência será estornada, os lançamentos nas contas correntes e contas a pagar e receber serão excluídos.'
        ),
      })
      if (!confirmed) return

      try {
        this.saving = true
        const url = transfer.type === 'Deposit' ? 'bank-deposit' : 'account-transfer'
        await this.$http.patch(`/api/financial/${url}/${transfer.id}/reverse`)

        this.showSuccess({ message: this.$t('Transação estornada com sucesso') })
        this.$emit('after-reverse')
        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    async reverseWriteOff() {
      if (!(this.isFromAccountPayable || this.isFromReceivable)) return

      const { receivableInstalment, accountsPayableInstalment } = this.data
      let writeOffId = null
      let writeOffMessage = ''

      if (this.isFromAccountPayable) {
        writeOffId = accountsPayableInstalment[0]?.financialWriteOffId
        writeOffMessage = this.$t(
          'O pagamento será cancelado e o lançamento na conta corrente será excluído.'
        )
      }

      if (this.isFromReceivable) {
        writeOffId = receivableInstalment[0]?.financialWriteOffId
        writeOffMessage = this.$t(
          'A baixa será cancelada e o lançamento na conta corrente será excluído.'
        )
      }

      const confirmed = await this.confirm({
        text: writeOffMessage,
      })
      if (!confirmed) return

      try {
        this.saving = true
        await this.$http.patch(`/api/financial/write-offs/${writeOffId}/reverse-payment`)
        this.showSuccess({ message: this.$t('Estornado com sucesso') })
        this.$emit('after-reverse')
        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    cleanData() {
      this.accountPayableList = []
      this.receivableList = []
      this.checkingAccountTransfer = null
    },
    hide() {
      this.cleanData()
      this.showSidebar = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
